/* MyCalendar.css */

/* Настраиваем стили для определенного числа (8 мая) */
.special-date {
    background-color: transparent; /* Любой желаемый цвет */
    border-radius: 50%;
}

/* Очищаем стандартные стили даты, чтобы избежать дублирования */
.no-data {
    background-color: transparent;
    border-radius: 0;
    /* Дополнительные стили по умолчанию, которые могут применяться к датам */
}
