.form-wrap {
    background-color: transparent;
    margin: 3em auto;
    border: 1px solid red;
    padding: 10px;
    border-radius: 20px;
}
.form-wrap .tabs {
    overflow: hidden;
}
.form-wrap .tabs h3 {
    float: left;
    width: 50%;
}
.form-wrap .tabs h3 a {
    padding: 0.5em 0;
    text-align: center;
    font-weight: 400;
    background-color: #e6e7e8;
    display: block;
    color: black;
}
.form-wrap .tabs h3 a.active {
    color: white;
    background-color: #ff0000;
}
.form-wrap .tabs-content {
    padding: 1.5em;
}
.form-wrap .tabs-content div[id$="tab-content"] {
    display: none;
}
.form-wrap .tabs-content .active {
    display: block !important;
}
.form-wrap form .input {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    color: inherit;
    font-family: inherit;
    padding: 0.8em 0 10px 0.8em;
    border: 1px solid #cfcfcf;
    outline: 0;
    display: inline-block;
    margin: 0 0 0.8em 0;
    padding-right: 2em;
    width: 100%;
}
.form-wrap form .button {
    width: 100%;
    padding: 0.8em 0 10px 0.8em;
    background-color: #a52828;
    border: none;
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
}
.form-wrap form .button:hover {
    background-color: #da4f4f;
}
.form-wrap form .checkbox {
    visibility: hidden;
    padding: 20px;
    margin: 0.5em 0 1.5em;
}
.form-wrap form .checkbox:checked + label:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    opacity: 1;
}
.form-wrap form label[for] {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
}
.form-wrap form label[for]:before {
    content: "";
    position: absolute;
    border: 1px solid #cfcfcf;
    width: 17px;
    height: 17px;
    top: 0px;
    left: -14px;
}
.form-wrap form label[for]:after {
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
    content: "";
    position: absolute;
    width: 9px;
    height: 5px;
    background-color: transparent;
    top: 4px;
    left: -10px;
    border: 3px solid #a52828;
    border-top: none;
    border-right: none;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.form-wrap .help-text {
    margin-top: 0.6em;
}
.form-wrap .help-text p {
    text-align: center;
    font-size: 14px;
}


.loginInput {
    width: 100%;
    border-radius: 20px;
}

.loginInput:focus-visible {
    outline-color: red ;
}

.buttonLogin {
    border-radius: 20px;
    border: 1px solid #ff2600;
    background-color: #ff2600;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: auto;
    display: flex;
}