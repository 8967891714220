/*// Устройства Small (телефоны с горизонтальной ориентацией, 576 пикселей и ниже)*/
@media only screen and (max-width: 576px) {
    .textInfo {
        text-align: center;
        width: 80%;
    }

    .image {
        padding-bottom: 40px;
        width: 100%;
        height: 60%;
    }

    .left_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        min-height: 30%;
    }

    .right_box {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
        min-height: 30%;
    }
}
/*// Устройства Medium (планшеты, 577 пикселей и выше)*/
@media only screen and (min-width: 577px) {
    .textInfo {
        text-align: center;
        width: 80%;
    }

    .image {
        padding-bottom: 40px;
        width: 100%;
        height: 80%;
    }

    .left_box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        text-align: end;
        height: 30%;
    }

    .right_box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: start;
        height: 30%;
    }
}
/*// Устройства Large (настольные компьютеры, 992 пикселей и выше)*/
@media only screen and (min-width: 992px) {
    .textInfo {
        text-align: center;
        width: 70%;
    }

    .image {
        padding-bottom: 40px;
        width: 100%;
        height: 80%;
    }

    .left_box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        text-align: end;
        height: 30%;
    }

    .right_box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: start;
        height: 30%;
    }
}
/*// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)*/
@media only screen and (min-width: 1200px) {
    .textInfo {
        text-align: center;
        width: 70%;
    }

    .image {
        padding-bottom: 40px;
        width: 100%;
        height: 80%;
    }

    .left_box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        text-align: end;
        height: 30%;
    }

    .right_box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: start;
        height: 30%;
    }
}
/*// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)*/
@media only screen and (min-width: 1400px) {
    .textInfo {
        text-align: center;
        width: 60%;
    }

    .image {
        padding-bottom: 40px;
        width: 100%;
        height: 80%;
    }

    .left_box {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
        text-align: end;
        height: 30%;
    }

    .right_box {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        text-align: start;
        height: 30%;
    }
}

.content {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}
