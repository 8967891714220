@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&family=Poppins:ital,wght@0,500;1,400&display=swap');

/*// Устройства Small (телефоны с горизонтальной ориентацией, 576 пикселей и ниже)*/
@media only screen and (max-width: 576px) {
    .textInfoBox {
        padding-top: 100px;
        min-height: 40%;
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .object {
        height: 50%;
        width: 80%;
    }
}
/*// Устройства Medium (планшеты, 577 пикселей и выше)*/
@media only screen and (min-width: 577px) {
    .textInfoBox {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .object {
        height: 60%;
        width: 70%;
    }
}
/*// Устройства Large (настольные компьютеры, 992 пикселей и выше)*/
@media only screen and (min-width: 992px) {
    .textInfoBox {
        height: 85%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .object {
        height: 60%;
        width: 40%;
    }
}
/*// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)*/
@media only screen and (min-width: 1200px) {
    .textInfoBox {
        height: 85%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .object {
        height: 60%;
        width: 40%;
    }
}
/*// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)*/
@media only screen and (min-width: 1400px) {
    .textInfoBox {
        height: 85%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .object {
        height: 60%;
        width: 30%;
    }
}

.helpText {
    font-family: 'Poppins', sans-serif;
    color: #ff0000;
}