@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

/*// Устройства Small (телефоны с горизонтальной ориентацией, 576 пикселей и ниже)*/
@media only screen and (max-width: 576px) {
}
/*// Устройства Medium (планшеты, 577 пикселей и выше)*/
@media only screen and (min-width: 577px) {
}
/*// Устройства Large (настольные компьютеры, 992 пикселей и выше)*/
@media only screen and (min-width: 992px) {
}
/*// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)*/
@media only screen and (min-width: 1200px) {
}
/*// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)*/
@media only screen and (min-width: 1400px) {
}


.helpText {
    font-family: 'Poppins', sans-serif;
    color: #ff0000;
    font-weight: 500;
    font-size: 18px;
}