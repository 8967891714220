/*// Устройства Small (телефоны с горизонтальной ориентацией, 576 пикселей и ниже)*/
@media only screen and (max-width: 576px) {
    .layout {
        padding: 0;
    }

    .content {
         display: flex;
         flex-direction: column;
         align-items: center;
         justify-content: center;
         padding: 0 10px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        left: 0;
    }
}

/*// Устройства Medium (планшеты, 577 пикселей и выше)*/
@media only screen and (min-width: 577px) {
    .layout {
        padding: 0;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        padding: 0 100px;
        width: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        left: 0;
    }
}

/*// Устройства Large (настольные компьютеры, 992 пикселей и выше)*/
@media only screen and (min-width: 992px) {
    .layout {
        display: flex;
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        padding: 0 100px;
        width: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        left: 0;
    }
}

/*// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)*/
@media only screen and (min-width: 1200px) {
    .layout {
        padding: 0 100px;
        display: flex;
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        padding: 0 100px;
        width: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        left: 0;
    }
}

/*// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)*/
@media only screen and (min-width: 1400px) {
    .layout {
        padding: 0 100px;
        display: flex;
        flex-direction: column;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 50px;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        padding: 0 100px;
        width: 100%;
        z-index: 100;
        background-color: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(10px);
        left: 0;
    }
}