#btn-gra{
    font-family: sans-serif;
    text-align: center;
    text-decoration: none;
    color: #fff;
    box-sizing: border-box;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    border: none;
    background-size: 400%;
    border-radius: 30px;
    z-index: 1;
    animation: animate 8s linear infinite;
    padding: 10px 20px;
    cursor: pointer;
}

@keyframes animate
{
    0%
    {
        background-position: 0%;
    }
    100%
    {
        background-position: 400%;
    }
}

#btn-gra:before{
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    bottom:-2px;
    right: -2px;
    z-index: -1;
    background: linear-gradient(90deg, #03a9f4, #f441a5, #ffeb3b, #03a9f4);
    background-size: 400%;
    border-radius: 40px;
    filter: blur(20px);
    opacity: 0;
    transition: 0.5s;
}

#btn-gra:hover:before{
    filter: blur(20px);
    opacity: 1;
    animation: animate 8s linear infinite;
}