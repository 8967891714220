.titleBlack {
    font-weight: bold;
    margin: 0;
}

.titleWhite {
    font-weight: bold;
    margin: 0;
    color: #000;
}

.titleInfo {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 10px 30px;
    color: white;
}

.social {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}

.buttonLogin {
    border-radius: 20px;
    border: 1px solid #ff2600;
    background-color: #ff2600;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}

.buttonLogin:active {
    transform: scale(0.95);
}

.buttonLogin:focus {
    outline: none;
}

.buttonLogin.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
}

.loginForm {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
}

.loginInput {
    width: 100%;
    border-radius: 20px;
}

.loginInput:focus-visible {
    outline-color: red ;
}

.container {
    background-color: transparent;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
}

.form_container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
}

.sign_in_container {
    left: 0;
    opacity: 1;
    width: 50%;
    z-index: 2;
}

.container.rightPanelActive .sign_in_container {
    opacity: 0;
    transform: translateX(0%);
}

.sign_up_container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
    transform: translateX(100%);
}

.container.rightPanelActive .sign_up_container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
}

@keyframes show {
    0%, 49.99% {
        opacity: 0;
        z-index: 1;
    }

    50%, 100% {
        opacity: 1;
        z-index: 5;
    }
}

.overlay_container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
}

.container.rightPanelActive .overlay_container{
    transform: translateX(-100%);
}

.overlay {
    background: #FF416C;
    background: -webkit-linear-gradient(to right, #FF4B2B, #ff0039);
    background: linear-gradient(to right, #FF4B2B, #ff003a);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.container.rightPanelActive .overlay {
    transform: translateX(50%);
}

.overlay_panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
}

.overlay_left {
    transform: translateX(-20%);
}

.container.rightPanelActive .overlay_left {
    transform: translateX(0);
}

.overlay_right {
    right: 0;
    transform: translateX(0);
}

.container.rightPanelActive .overlay_right {
    transform: translateX(20%);
}

.social_container {
    margin: 20px 0;
}

.social_container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
}