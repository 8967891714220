/*// Устройства Small (телефоны с горизонтальной ориентацией, 576 пикселей и ниже)*/
@media only screen and (max-width: 576px) {
    .textInfo {
        text-align: center;
        width: 80%;
    }

    .content {
        margin-top: 150px;
    }
}
/*// Устройства Medium (планшеты, 577 пикселей и выше)*/
@media only screen and (min-width: 577px) {
    .textInfo {
        text-align: center;
        width: 80%;
    }
}
/*// Устройства Large (настольные компьютеры, 992 пикселей и выше)*/
@media only screen and (min-width: 992px) {
    .textInfo {
        text-align: center;
        width: 70%;
    }
}
/*// Устройства X-Large (большие настольные компьютеры, 1200 пикселей и выше)*/
@media only screen and (min-width: 1200px) {
    .textInfo {
        text-align: center;
        width: 70%;
    }
}
/*// Устройства XX-Large (большие настольные компьютеры, 1400 пикселей и выше)*/
@media only screen and (min-width: 1400px) {
    .textInfo {
        text-align: center;
        width: 60%;
    }

    .image {
        width: 80%;
    }
}

.image {
    padding-bottom: 40px;
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
}