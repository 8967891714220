.swiper-slide-shadow {
    background: rgba(0, 0, 0, 0) !important;
}

.ant-card-head {
    border-bottom: 0 !important;
}

.mySwiperPricing {
    display: flex;
    width: 100%;
    height: 100%;
}