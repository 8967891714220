@media only screen and (max-width: 576px) {
    #Pricing {
        padding-bottom: 40px;
    }
}

.swiperBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100%;
}

.mySwiper {
    display: flex;
    width: 100%;
    height: 75%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}